<template>
    <div class="page-table mainDiv scrollable only-y" id="affix-container">
        <div class="card-base baslikBg animated fadeInUp">
            {{$t("src.views.apps.iletisim.liste.title")}}

            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" size="mini" type="text" icon="el-icon-refresh"></el-button>
                </el-tooltip>
            </div>
        </div>

        <div class="page-header card-base card-shadow--medium animated fadeInUp" v-loading="loading || stateLoading"
        :element-loading-text='loading ? $t("src.views.apps.iletisim.liste.loading") : $t("src.views.apps.iletisim.liste.stateLoading")'
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 1)">
        <el-row class="mt-0 mb-10">
            <el-col :lg="12" :md="12" :sm="24" :xs="24">
                <div>
                    <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9" text-color="gray">
                        <el-radio-button label="1">
                            <i class="mdi mdi-email mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                            <transition name="slide-fade"><label v-if="selectIcon==='1'">{{$t("src.views.apps.iletisim.liste.pasif")}}</label>
                            </transition>
                        </el-radio-button>
                        <el-radio-button label="0">
                            <i class="mdi mdi-email-open mdi-18px" style="color: #f7ba2a; margin-right: 5px"></i>
                            <transition name="slide-fade"><label v-if="selectIcon==='0'">{{$t("src.views.apps.iletisim.liste.aktif")}}</label>
                            </transition>
                        </el-radio-button>
                    </el-radio-group>
                </div>
            </el-col>

            <el-col :lg="12" :md="12" :sm="24" :xs="24">
                <el-col :lg="24" :md="24" :sm="24" :xs="24">
                    <ul class="islemler">
                        <li style="color: #f7ba2a; position: relative; top: 3px">
                            <i class="mdi mdi-circle-edit-outline mdi-24px"></i> {{$t("src.views.apps.genel.detay")}}
                        </li>
                        <li style="">
                            <i class="mdi mdi-play"></i> {{$t("src.views.apps.iletisim.liste.saveToPassive")}}
                        </li>
                        <li style="color:#ec205f">
                            <i class="mdi mdi-pause"></i> {{$t("src.views.apps.iletisim.liste.saveToActive")}}
                        </li>
                    </ul>
                </el-col>
            </el-col>
        </el-row>

        <el-table border stripe v-if="iletisimListe.length > 0" @cell-click="cellClick" v-loading="loading" :data="iletisimListe" ref="filterTable" style="width: 100%">
            <el-table-column
                :label='$t("src.views.apps.genel.sira")'
                type="index"
                :index="indexMethod"
                width="80">
            </el-table-column>
            <el-table-column
                prop="iletisimID"
                :label='$t("src.views.apps.iletisim.liste.table.id")'
                width="110">
            </el-table-column>
            <el-table-column
                prop="adSoyad"
                :label='$t("src.views.apps.iletisim.liste.table.nameSurname")'
                width="350">
            </el-table-column>
            <el-table-column
                prop="telefon"
                :label='$t("src.views.apps.iletisim.liste.table.telephone")'
                width="auto">
                <template slot-scope="prop">
                    <div class="new-line-text">
                        {{prop.row.telefon}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="eposta"
                :label='$t("src.views.apps.iletisim.liste.table.email")'
                width="300">
            </el-table-column>
            <el-table-column
                prop="mesaj"
                :label='$t("src.views.apps.iletisim.liste.table.message")'
                width="400">
                <template slot-scope="prop">
                    {{prop.row.mesaj.length > 45 ? prop.row.mesaj.slice(0,45) + '...' : prop.row.mesaj}}
                </template>
            </el-table-column>
            <el-table-column
                :label='$t("src.views.apps.genel.islem")'
                align="right"
                width="100">
                <template slot-scope="scope">
                    <el-tooltip :content='$t("src.views.apps.genel.detay")' :open-delay="100" placement="bottom">
                        <el-button
                            type="text"
                            @click="openDialog(scope.row)"
                            class="mr-10" style="color: #f7ba2a"
                            icon="mdi mdi-circle-edit-outline mdi-24px">
                        </el-button>
                    </el-tooltip>

                    <el-tooltip :content='$t("src.views.apps.iletisim.liste.saveToPassive")' :open-delay="100" placement="bottom">
                        <el-button
                            type="text"
                            v-if="selectIcon==='0'"
                            style="margin-left: 15px !important"
                            @click="iletisimDurumGuncelle(scope.row.iletisimID, 1)"
                            icon="mdi mdi-play mdi-24px">
                        </el-button>
                    </el-tooltip>

                    <el-tooltip :content='$t("src.views.apps.iletisim.liste.saveToActive")' :open-delay="100" placement="bottom">
                        <el-button
                            class="buttonDel"
                            style="margin-left: 15px !important"
                            v-if="selectIcon==='1'"
                            type="text"
                            @click="iletisimDurumGuncelle(scope.row.iletisimID, 0)"
                            icon="mdi mdi-pause mdi-24px">
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <div v-else style="text-align:center">
            {{$t("src.views.apps.messages.noData")}}
        </div>
        <el-pagination
            background
            layout="sizes,prev, pager, next, jumper, total"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="pageChange"
            :page-sizes="[10, 20, 50, 100]"
            :current-page.sync="page"
            :next-click.sync="page"
            :prev-click.sync="page"
            @next-click="pageChange"
            @prev-click="pageChange">
        </el-pagination>
    </div>

    <el-dialog :visible="dialogVisible" @close="dialogVisible = false">
        <div>
            <el-row>
                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="mb-10" align="center"><b>{{dialogData.adSoyad}}</b></el-col> <hr class="mb-10" />  

                <el-col :lg="5" :md="5" :sm="8" :xs="9" class="mb-10">{{$t("src.views.apps.iletisim.liste.table.email")}}</el-col>                    
                <el-col :lg="1" :md="1" :sm="1" :xs="1" class="mb-10">:</el-col>                    
                <el-col :lg="18" :md="18" :sm="15" :xs="14" class="mb-10">{{dialogData.eposta === "" ? "-" : dialogData.eposta }}</el-col> 

                <el-col :lg="5" :md="5" :sm="8" :xs="9" class="mb-10">{{$t("src.views.apps.iletisim.liste.table.telephone")}}</el-col>          
                <el-col :lg="1" :md="1" :sm="1" :xs="1" class="mb-10">:</el-col>             
                <el-col :lg="18" :md="18" :sm="15" :xs="14" class="mb-10">{{dialogData.telefon === "" ? "-" : dialogData.telefon}}</el-col> 

                <el-col :lg="5" :md="5" :sm="8" :xs="9" class="mb-10">{{$t("src.views.apps.iletisim.liste.table.message")}}</el-col>          
                <el-col :lg="1" :md="1" :sm="1" :xs="1" class="mb-10">:</el-col>            
                <el-col :lg="18" :md="18" :sm="15" :xs="14" class="mb-10">{{dialogData.mesaj === "" ? "-" : dialogData.mesaj}}</el-col>          
            </el-row>
        </div>
    </el-dialog>
</div>
</template>

<script>
    import iletisimService from '../../../WSProvider/IletisimService';
    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'

    export default {
        name: "SiparisListesi",
        components : {
        },
        data() {
            return {
                dialogData: "",
                dialogVisible: false,
                page: 0,
                sizes: 10,
                postPage: 0,
                total: null,
                loading: false,
                stateLoading: false,
                iletisimListe: [],
                selectIcon: "1",
                radio: '1',
            }
        },
        watch: {
            page(val) {
                this.postPage = (val - 1) * this.sizes;
            },
        },
        mounted() {
            this.getIletisimList()
            this.getIletisimCount();
        },
        methods: {
            refreshPage(){
                this.loading = false;
                this.stateLoading = false;
                this.page = 1;
                this.postPage = 0;
                this.getIletisimList()
                this.getIletisimCount();
            },

            openDialog(scope){
                this.dialogVisible = true;
                this.dialogData = scope
            },
            
            handleChange(event) {
                this.page = 1;
                this.postPage = 0;
                this.selectIcon = event;
                this.getIletisimList()
                this.getIletisimCount();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },

            getIletisimList() {
                this.stateLoading = false;
                this.loading = true;
                iletisimService.iletisimList(this.selectIcon, this.postPage, this.sizes).then((response) => {
                    if (response.status == 200) {
                        localStorage.setItem("userDataBGSurec", response.token)
                        response.data.forEach(element => {
                            element = functions.removeSlashesAndDecodeStrings(element)
                        });
                        this.iletisimListe = response.data
                    }
                    this.loading = false;
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }else if(error.status == 404){
                            this.iletisimListe = [];
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                    }else{
                        //notification.Status(503, this)
                    }
                    this.loading = false;
                })
            },

            getIletisimCount() {
                iletisimService.iletisimSayisi(this.selectIcon).then((response) => {
                    if (response.status == 200) {
                        localStorage.setItem("userDataBGSurec", response.token)
                        this.total = response.data;
                    }
                    this.loading = false;
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }else if(error.status == 404){
                            this.total = 0
                        }
                    }else{
                        //notification.Status(503, this)
                    }
                })
            },

            iletisimDurumGuncelle(iletisimID, durum){
                var msg = durum == '0' ? "İletişimi taslağa kaldırmak istediğinize emin misiniz?" : "İletişimi aktif yapmak istediğinize emin misiniz?"
                this.$confirm(msg, this.$t("src.views.apps.genel.uyari"), {
                    confirmButtonText: this.$t("src.views.apps.genel.yes"),
                    cancelButtonText: this.$t("src.views.apps.genel.no"),
                    type: 'warning'
                }).then(() => {
                    this.stateLoading = true;
                    iletisimService.iletisimDurumGuncelle(iletisimID, durum).then(response => {
                        if(response.status == 200){
                            localStorage.setItem("userDataBGSurec", response.token)
                            this.getIletisimList()
                            this.getIletisimCount();
                        }
                        this.stateLoading = false;
                        notification.Status("success", this, response.msg)
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }else{
                            //notification.Status(503, this)
                        }
                        this.stateLoading = false;
                    })
                }).catch(e => {})
            },

            cellClick(row, column, cell, event){
                if(column.property !== undefined){
                    this.dialogVisible = true;
                    this.dialogData = row;
                }
            },

            handleSizeChange(val) {
                this.sizes = val;
                this.getIletisimList()
                this.getIletisimCount();
            },
            
            pageChange(val) {
                this.postPage = (val - 1) * this.sizes;
                this.getIletisimList()
                this.getIletisimCount();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },

            indexMethod(index){
                return ((this.page - 1)* 10) + index + 1;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .el-pagination {
        margin-top: 20px;
        float: right;
    }

    .el-radio-button--mini .el-radio-button__inner label{
        position: relative;
        bottom: 5px;
    }
</style>
<style lang="scss">
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .el-radio-button--mini .el-radio-button__inner{
        padding: 0 10px !important;
        font-size: 11px;
        border-radius: 0;
    }
    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }

    .el-drawer:focus {
        outline: none;
    }

    .el-drawer__header span:focus{
        outline: none;
    }
</style>